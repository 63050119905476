<template>
  <section class="header-wrapper">
    <div>
      <h1>CS2<span class="hoverable">Master</span></h1>
      <h2>The <span class="hoverable">BEST</span> CS2 servers</h2>
    </div>
    <div class="forum-links">
      <NuxtLink to="/forums/t/giveaway">{{ $t('giveaways') }}</NuxtLink>
      <NuxtLink to="/forums/t/atrankos">{{ $t('superviserSelection') }}</NuxtLink>
      <NuxtLink to="/forums/t/atsibaninimo-anketa">{{ $t('unban') }}</NuxtLink>
    </div>
    <h3 @click="scrollToServers" class="join-now">{{ $t('joinNow') }} <Icon name="lucide:chevrons-down" /></h3>
    <app-home-background />
  </section>
  <section class="servers" id="servers">
    <h2>{{ $t('servers') }}</h2>
    <app-server-list />
  </section>
</template>

<script setup lang="ts">
useHead({
  title: 'CS2Master - The Best CS2 Public Server',
});

const { $router } = useNuxtApp();

function scrollToServers() {
  $router.push('#servers');
}
</script>

<style lang="sass" scoped>
.header-wrapper
  height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  justify-content: center
  gap: 2rem
  position: relative
  z-index: 20

  & > div
    padding-top: 10rem
    margin: auto

  h1, h2
    color: #ffaa00
    span.hoverable
      color: #77A
      transition: all .2s
      &:hover
        color: #ffaa00
  h1
    font-size: 7rem
  h2
    font-size: 4rem

  .join-now
    color: #fa0
    font-size: 3rem
    margin: auto auto 5rem
    padding: 1.5rem 2rem
    border-radius: 2rem
    cursor: pointer
    transition: all .2s
    &:hover
      background: #ffaa00
      color: #224

.forum-links
  display: flex
  gap: 2rem
  flex-wrap: wrap
  justify-content: center
  & > *
    padding: 2rem
    width: 20rem
    background: #222244
    color: #ffaa00
    border: 3px solid transparent
    border-radius: 2rem
    font-size: 1.6rem
    transition: .2s all
    &:hover
      border: 3px solid #ffaa00

section.servers
  padding: 4rem 0
  height: 100vh
</style>
